const eventConfig = {
  'aab052024': {
    title: 'To Agile... and Beyond!',
    description: "Thank you for attending Caitlin's talk! Let's connect and talk more about asynchronous collaboration at your organization. Please share any thoughts or questions below.",
    qrLocation: 'handout'
  },
  'ADC24': {
    title: 'Embracing Asynchronous Collaboration',
    description: "Thank you for participating in our asynchronous conversation, Let's connect and talk more about how asynchronous collaboration can be leveraged at your organization!",
    qrLocation: 'meetup'
  },
  'abm032025': {
    title: 'Agile Brigade Meetup\nMarch 2025',
    description: "Thank you for attending the Agile Brigade Meetup! Let's continue our conversation about asynchronous practices and how we can help your organization thrive.",
    qrLocation: 'meetup'
  }
};

export default eventConfig;
